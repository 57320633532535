import { environment } from '../../../../environments/environment';
import { AfterViewInit, Component, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-order-now-button',
  templateUrl: './order-now-button.component.html',
  styleUrls: ['./order-now-button.component.scss'],
})
export class OrderNowButtonComponent implements AfterViewInit {
  @ViewChild('triggerOrderNow') trigger!: ElementRef;

  gloriaFoodCuid = environment.gloriaFoodCuid;
  gloriaFoodRuid = environment.gloriaFoodRuid;

  ngAfterViewInit(): void {
    this.trigger.nativeElement.href =
      '#!?generated_time=' + new Date().getTime();
  }
}
