import { CoreApiService } from './../../../core-api.service';
import { Component } from '@angular/core';
import { config } from '@shared/config';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent {
  readonly config = config.storefront;
  loading = false;
  messageSent = false;

  contactForm = this.fb.group({
    firstName: [''],
    lastName: [''],
    contactNumber: [''],
    email: [''],
    message: [''],
  });
  constructor(
    private coreApiService: CoreApiService,
    private fb: FormBuilder
  ) {}

  submit() {
    this.loading = true;
    this.contactForm.disable();

    this.coreApiService
      .sendContactUsMail({
        ...this.contactForm.value,
      })
      .subscribe({
        next: () => {
          this.loading = false;
          this.messageSent = true;
        },
        error: (err) => {
          this.contactForm.enable();
          this.loading = false;
          console.error(err);
        },
      });
  }

  hasControlErrors(controlName: string) {
    const controlErrors = this.getControlErrors(controlName);

    if (!controlErrors) {
      return false;
    }

    return controlErrors;
  }

  getControlErrors(controlName: string) {
    const control = this.contactForm.get(controlName);

    if (control && control.invalid && control.dirty && control.touched) {
      return control.errors;
    } else return null;
  }
}
