import { IConfig } from './config.interface';

export class ConfigHelper {
  constructor(private config: IConfig) {}

  get appDomainName(): string {
    return `${this.config.appSubDomainPrefix}.${this.config.domainName}`;
  }

  get apiDomainName() {
    if (this.config.apiSubDomainPrefix && this.config.domainName) {
      return `${this.config.apiSubDomainPrefix}.${this.config.domainName}`;
    }
    return;
  }

  get apiUrl(): string {
    if (this.apiDomainName) {
      return `https://${this.apiDomainName}`;
    }

    return `https://api.storefront.readyorder.com.au`;
  }

  get apiDefaultUrl(): string {
    return `https://${this.apiDomainName}`;
  }

  get appUrl(): string {
    return `https://${this.appDomainName}`;
  }
}
