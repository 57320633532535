import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { config } from '@shared/config';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  readonly config = config.storefront;
  classApplied = false;
  constructor(public router: Router) {}

  toggleClass() {
    this.classApplied = !this.classApplied;
  }
}
