<div class="feedback-area ptb-100">
  <div class="container">
    <div class="section-title">
      <span class="text">Testimonials</span>
      <h2>What People are saying</h2>
    </div>
    <div class="feedback-slides">
      <owl-carousel-o [options]="feedbackSlides">
        <ng-template carouselSlide *ngFor="let testimonial of testimonials">
          <div class="single-feedback">
            <p>
              {{ testimonial.message }}
            </p>
            <div class="client-info">
              <h3>{{ testimonial.userName }}</h3>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</div>
