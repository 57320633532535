import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IMenu } from './interfaces/menu.interface';

@Injectable({
  providedIn: 'root',
})
export class GlobalFoodService {
  constructor(private http: HttpClient) {}

  fetchMenu() {
    return this.http.get<IMenu>(`${environment.apiUrl}/gf/pos/menu`, {
      headers: {
        Authorization: environment.restaurantId,
      },
    });
  }
}
