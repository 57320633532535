import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { GlobalFoodService } from '../../../global-food.service';
import { IMenu } from './../../../interfaces/menu.interface';

@Component({
  selector: 'app-restaurant-menu',
  templateUrl: './restaurant-menu.component.html',
  styleUrls: ['./restaurant-menu.component.scss'],
})
export class RestaurantMenuComponent {
  $menu: Observable<IMenu>;
  constructor(
    public router: Router,
    private globalFoodService: GlobalFoodService
  ) {
    this.$menu = this.globalFoodService.fetchMenu().pipe(
      map((data) => {
        data.categories = data.categories.filter((cat) => cat?.active);
        return data;
      })
    );
  }
}
