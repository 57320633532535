<footer class="footer-area">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6">
        <p>
          Copyright <i class="icofont-copyright"></i>
          {{ copyrightYear }}
          Ready Order. All rights reserved
        </p>
      </div>

      <div class="col-lg-6 col-md-6">
        <ul>
          <li>
            <span>Developed with ❤️ by</span>
            <a href="https://www.edgetech.studio/"> <b>Edge Tech</b></a>
            <span> for</span>
            <a href="https://www.readyorder.com.au"><b>Ready Order</b></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
