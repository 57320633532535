<div class="not-found-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="not-found-content">
          <img src="assets/img/error.png" alt="error-image" />
          <h3>Page Doesn't Exist</h3>
          <p>
            The page you are looking for might have been removed had its name
            changed or is temporarily unavailable.
          </p>
          <a routerLink="/" class="btn btn-primary">Back to Home</a>
        </div>
      </div>
    </div>
  </div>
</div>
