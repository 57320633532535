<div id="menu" class="menu-area ptb-100">
  <div class="container">
    <div class="section-title">
      <div class="title-items">
        <span class="text">Discover</span>
        <h2>Our Special Menu</h2>
      </div>
      <app-order-now-button></app-order-now-button>
    </div>

    <!-- Restaurant Menu -->
    <div class="row d-flex" *ngIf="$menu | async as menu">
      <div class="col-lg-6 col-md-6" *ngFor="let category of menu?.categories">
        <div class="fryio-food-menu">
          <h3>{{ category.name }}</h3>
          <div *ngFor="let menuItem of category.items" class="single-box-menu">
            <div class="menu-content">
              <h4>
                {{ menuItem.name }}
                <span class="price">{{
                  menuItem.sizes?.length
                    ? 'From $' + menuItem.price
                    : '$' + menuItem.price
                }}</span>
              </h4>
              <p>
                {{ menuItem.description }}
              </p>
              <ng-container *ngIf="menuItem.sizes?.length">
                <br />
                Available in
                <div *ngFor="let size of menuItem.sizes">
                  <p *ngIf="!!size">
                    {{ size.name }}
                    <span class="price"
                      >${{ menuItem.price + size.price }}</span
                    >
                  </p>
                </div>
              </ng-container>
              <p *ngIf="menuItem.tags?.length" class="accent">
                <br />
                {{ menuItem.tags?.join(' | ') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="line-bg"><img src="assets/img/line.png" alt="line" /></div>
</div>
