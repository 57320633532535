import { config } from '@shared/config';
import { environment } from './../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface IContactUs {
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: string;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class CoreApiService {
  constructor(private http: HttpClient) {}

  sendContactUsMail(contactUsData: IContactUs) {
    return this.http.post(`${environment.apiUrl}/contact-us`, {
      ...contactUsData,
      config: {
        emailTo: config.awsMailHostingProvider.emailToAddress,
        subject: config.awsMailHostingProvider.emailSubject,
      },
    });
  }
}
