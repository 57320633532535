import { config, ConfigHelper } from '@shared/config';

const configHelper = new ConfigHelper(config);

export const environment = {
  production: true,
  restaurantId: config.storefront.restaurantId,
  fetchMenuUrl: config.storefront.gloriaFoodUrl,
  gloriaFoodCuid: config.storefront.gloriaFoodCuid,
  gloriaFoodRuid: config.storefront.gloriaFoodRuid,
  apiUrl: configHelper.apiUrl,
};
