/* eslint-disable @typescript-eslint/no-empty-function */
import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  location: unknown;
  routerSubscription: unknown;

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller
  ) {
    window.addEventListener('touchstart', () => {}, true);
    window.addEventListener('gesturestart', () => {}, true);
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter((e) => e instanceof Scroll))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((e: any) => {
        setTimeout(() => {
          if (e.position) {
            this.viewportScroller.scrollToPosition(e.position);
          } else if (e.anchor) {
            this.viewportScroller.scrollToAnchor(e.anchor);
          } else {
            window.location.hash = '#!';
          }
        }, 300);
      });
  }
}
